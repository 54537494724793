@charset "utf-8";

@import "minimal-mistakes/skins/mint"; // skin
@import "minimal-mistakes"; // main partials

.width-half {
    width: 50%
}

.width-40pct {
    width: 40%
}
